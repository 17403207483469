type ToggleBodyScroll = {
  toggleBodyScroll: () => void;
  disableScroll: () => void;
  enableScroll: () => void;
};

export function useToggleBodyScroll(): ToggleBodyScroll {
  const toggleBodyScroll = () => {
    document.body.classList.toggle('scrollable');
  };

  const enableScroll = () => {
    document.body.classList.remove('scrollable');
  };

  const disableScroll = () => {
    document.body.classList.add('scrollable');
  };

  return { toggleBodyScroll, disableScroll, enableScroll };
}
