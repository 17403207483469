const envVars = {
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
  backendBaseUrl: process.env.NEXT_PUBLIC_BACKEND_BASE_URL ?? '',
  backendAPIKey: process.env.NEXT_PUBLIC_BACKEND_API_KEY ?? '',
  cmsGraphqlUrl: process.env.NEXT_PUBLIC_CMS_GRAPHQL_URL ?? '',
  cmsGroqUrl: process.env.NEXT_PUBLIC_CMS_GROQ_URL ?? '',
  guestyApiBaseUrl: process.env.NEXT_PUBLIC_GUESTY_BASE_URL ?? '',
  guestyApiUsername: process.env.NEXT_PUBLIC_GUESTY_API_USERNAME ?? '',
  guestyApiPassword: process.env.NEXT_PUBLIC_GUESTY_API_PASSWORD ?? '',
  isServerSide: typeof window === 'undefined',
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? '',
  segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
  activeCampaignApiKey: process.env.NEXT_PUBLIC_ACTIVE_CAMPAIGN_API_KEY ?? '',
  activeCampaignApiUrl: process.env.NEXT_PUBLIC_ACTIVE_CAMPAIGN_API_URL ?? '',
  airDNAAPIClientToken: process.env.NEXT_PUBLIC_AIRDNA_API_CLIENT_TOKEN ?? '',
};

export default envVars;
