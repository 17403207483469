import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export enum ModalNames {
  MOBILE_MENU = 'MOBILE_MENU',
}

type ContextValues = {
  openModal: (modalName: ModalNames) => void;
  closeModal: (modalName: ModalNames) => void;
  openModals: ModalNames[];
};

const ModalsContext = createContext<ContextValues>({
  openModal: (_modalName: ModalNames) => {},
  closeModal: (_modalName: ModalNames) => {},
  openModals: [],
});
ModalsContext.displayName = 'ModalsContext';

export function ModalsProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const { enableScroll, disableScroll } = useToggleBodyScroll();
  const [openModals, setOpenModals] = useState<ModalNames[]>([]);

  const openModal = (modalName: ModalNames) => {
    disableScroll();
    setOpenModals((modals) => {
      const mds = [...modals];
      mds.push(modalName);
      return mds;
    });
  };

  const closeModal = (modalName: ModalNames) => {
    enableScroll();
    setOpenModals((modals) => {
      const mds = [...modals];
      mds.splice(modals.indexOf(modalName), 1);
      return mds;
    });
  };

  return (
    <ModalsContext.Provider value={{ openModal, closeModal, openModals }}>
      {children}
    </ModalsContext.Provider>
  );
}

export function useModals() {
  const context = useContext(ModalsContext);
  if (context === undefined) {
    throw new Error('useModals must be used within an ModalsProvider');
  }
  return context;
}
