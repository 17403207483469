import _isEmpty from 'lodash/isEmpty';
import { SessionTokensType } from '@utils/types/sessionTokens';
import { ListingInfoLocalStorage } from '@utils/types/listingInfoLocalStorage';

export function setSessionTokens(
  sessionTokens: Partial<SessionTokensType> = {}
) {
  if (_isEmpty(sessionTokens)) {
    localStorage.removeItem('sessionTokens');
  } else {
    localStorage.setItem('sessionTokens', JSON.stringify(sessionTokens));
  }
}

export function getSessionTokens(): SessionTokensType | undefined {
  if (typeof localStorage === 'undefined') {
    return undefined;
  }

  const values = localStorage.getItem('sessionTokens');

  if (values) {
    return JSON.parse(values);
  }
}

export function setLastClearedDate(clearedDate?: string) {
  localStorage.setItem('clearedDate', JSON.stringify(clearedDate));
}

export function getLastClearedDate(): string | undefined {
  const values = localStorage.getItem('clearedDate');

  if (values) {
    return JSON.parse(values);
  }
}

export function setListingInfo(listingInfo: ListingInfoLocalStorage) {
  try {
    localStorage.setItem('listingInfo', JSON.stringify(listingInfo));
  } catch (e) {
    console.log(`Unhandled error - ${e}`);
  }
}

export function getListingInfo(): ListingInfoLocalStorage | undefined {
  const values = localStorage.getItem('listingInfo');

  if (values) {
    return JSON.parse(values);
  }
}
